import { ReactNode } from 'react'
import styled from 'styled-components'
import { absurd, must } from '../../util/exhaustiveness'
import { Change, Dispatch, Program } from 'center/compiled/util/raj'
import { Button, ButtonLink, ButtonSet } from '../../views/button'
import { BigHeading, Heading, Paragraph } from '../../views/card'
import { Form } from '../../views/form'
import { GlobalStyle } from '../../views/global'
import { Pad, Split, SplitBlock, SplitPriority } from '../../views/spacing'
import { TextBox, TextField } from '../../views/text-field'
import logo from './logo.svg'

type Page = 'intro' | 'brainstorm' | 'rank' | 'review'

type Msg =
  | { type: 'change_page'; page: Page }
  | { type: 'update_brainstorm_text_value'; value: string }
  | { type: 'add_brainstorm_idea' }
  | { type: 'remove_brainstorm_idea'; idea: string }
  | { type: 'add_idea_to_slot'; idea: string }
  | { type: 'fill_slot'; slotIndex: number }
  | { type: 'update_entry'; slotIndex: number; entryChanges: Partial<Entry> }
  | { type: 'move_issue'; direction: 'up' | 'down'; slotIndex: number }
  | { type: 'remove_issue'; slotIndex: number }

type Resource = {
  title: string
  description?: string
  link?: string
}

type Issue = {
  title: string
  position: string
  important: string
  relativeImportance: string
  resources: Resource[]
}

export type IssuePacket = {
  version: '1'
  issues: [Issue]
}

type FilledSlot = {
  type: 'filled'
  entry: Entry
}

type Entry = {
  title: string
  position: string
  importance: string
  relativeImportance: string
  resources: Resource[]
}

type TopSlot = FilledSlot | { type: 'empty' }

type Model = {
  page: Page
  brainstormTextValue: string
  slots: [TopSlot, TopSlot, TopSlot]
  otherEntries: Entry[]
}

export const appProgram: Program<Msg, Model, ReactNode> = {
  init: [
    {
      page: 'intro',
      brainstormTextValue: '',
      slots: [{ type: 'empty' }, { type: 'empty' }, { type: 'empty' }],
      otherEntries: [],
    },
  ],
  update,
  view,
}

function makeEmptyFilledSlot(title?: string): FilledSlot {
  return {
    type: 'filled',
    entry: {
      title: title || '',
      position: '',
      importance: '',
      relativeImportance: '',
      resources: [],
    },
  }
}

function isPresent(value: string): boolean {
  return !!(value && value.trim().length > 0)
}

function isEmptyEntry(entry: Entry): boolean {
  const { title, position, importance, relativeImportance, resources } = entry
  return !(
    isPresent(title) ||
    isPresent(position) ||
    isPresent(importance) ||
    isPresent(relativeImportance) ||
    resources.length > 0
  )
}

function update(msg: Msg, model: Model): Change<Msg, Model> {
  switch (msg.type) {
    case 'change_page': {
      return [{ ...model, page: msg.page }]
    }
    case 'update_brainstorm_text_value':
      return [{ ...model, brainstormTextValue: msg.value }]
    case 'add_brainstorm_idea': {
      const title = model.brainstormTextValue.trim()
      const otherEntries =
        title && !model.otherEntries.find((e) => e.title === title)
          ? [
              makeEmptyFilledSlot(model.brainstormTextValue).entry,
              ...model.otherEntries,
            ]
          : model.otherEntries

      return [
        {
          ...model,
          brainstormTextValue: '',
          otherEntries,
        },
      ]
    }
    case 'remove_brainstorm_idea':
      return [
        {
          ...model,
          otherEntries: model.otherEntries.filter((x) => x.title !== msg.idea),
        },
      ]
    case 'add_idea_to_slot': {
      const entry = model.otherEntries.find((x) => x.title === msg.idea)
      if (!entry) {
        return [model]
      }

      const freeSlot =
        model.slots.findIndex((x) => x.type === 'empty') ??
        model.slots.length - 1
      const slotValue = must(model.slots[freeSlot])

      switch (slotValue.type) {
        case 'empty': {
          model.otherEntries = model.otherEntries.filter((x) => x !== entry)
          break
        }
        case 'filled': {
          model.otherEntries = [
            slotValue.entry,
            ...model.otherEntries.filter((x) => x !== entry),
          ]
          break
        }
        default:
          return absurd(slotValue)
      }

      model.slots[freeSlot] = { type: 'filled', entry }
      return [model]
    }
    case 'fill_slot': {
      model.slots[msg.slotIndex] = makeEmptyFilledSlot()
      return [model]
    }
    case 'update_entry': {
      const slot = model.slots[msg.slotIndex]!
      switch (slot.type) {
        case 'empty': {
          return [model]
        }
        case 'filled': {
          Object.assign(slot.entry, msg.entryChanges)
          return [model]
        }
        default:
          return absurd(slot)
      }
    }
    case 'move_issue': {
      const newIndex = msg.slotIndex + (msg.direction === 'up' ? -1 : 1)
      if (newIndex < 0) {
        return [model]
      }

      if (newIndex > 2) {
        const slot = model.slots[msg.slotIndex]!
        const currentEntry = must(
          slot.type === 'filled' ? slot.entry : undefined
        )

        const firstExtraEntry = model.otherEntries.shift()
        model.slots[msg.slotIndex] = firstExtraEntry
          ? {
              type: 'filled',
              entry: firstExtraEntry,
            }
          : { type: 'empty' }

        if (!isEmptyEntry(currentEntry)) {
          model.otherEntries.unshift(currentEntry)
        }

        return [model]
      }

      const oldValue = model.slots[newIndex]
      model.slots[newIndex] = model.slots[msg.slotIndex]!
      model.slots[msg.slotIndex] = oldValue || { type: 'empty' }
      return [model]
    }
    case 'remove_issue': {
      const slot = model.slots[msg.slotIndex]
      if (!(slot && slot.type === 'filled')) {
        return [model]
      }

      if (!isEmptyEntry(slot.entry)) {
        model.otherEntries.unshift(slot.entry)
      }

      model.slots[msg.slotIndex] = { type: 'empty' }
      return [model]
    }
    default:
      absurd(msg)
  }
}

function view(model: Model, dispatch: Dispatch<Msg>) {
  return (
    <>
      <GlobalStyle />
      {pageView(model, dispatch)}
    </>
  )
}

const PageContainer = styled.div`
  padding: 1rem;
  max-width: 960px;
  margin: 0px auto;
`

const Slot = styled.div``

const SlotEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  min-height: 15vh;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.05);
  text-align: center;

  &:hover {
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
`

const RankBadge = styled.div`
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 50%;
  background-color: #d5eafd;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SlotCard = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
`

const SlotPadding = styled.div`
  padding: 1rem;
`

const FormPadding = styled.div`
  padding: 0.5rem;
`

const CardFooter = styled.div`
  // border-top: 1px solid #ddd;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.5rem 1rem;
`

const LargeRule = styled.hr`
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  height: 0.25rem;
`

const FormInputSeparator = styled.div`
  width: 0.5rem;
  height: 0.5rem;
`

function brainstormAddForm(
  model: Model,
  submitText: string,
  autoFocus: boolean,
  dispatch: Dispatch<Msg>
) {
  return (
    <Form onSubmit={() => dispatch({ type: 'add_brainstorm_idea' })}>
      <Split>
        <SplitPriority>
          <TextBox
            {...{
              isEnabled: true,
              isRequired: true,
              autoFocus,
              value: model.brainstormTextValue,
              onValue(text) {
                dispatch({
                  type: 'update_brainstorm_text_value',
                  value: text,
                })
              },
            }}
          />
        </SplitPriority>
        <FormInputSeparator />
        <Button
          {...{
            type: 'submit',
            isEnabled: true,
            title: submitText,
          }}
        />
      </Split>
    </Form>
  )
}

const IssueTagList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const IssueTag = styled.li`
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin: 0 0.5rem 0.5rem 0;

  &:hover {
    border-color: #bbb;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
`

const IssueTagText = styled.p<{ clickable: boolean }>`
  padding: 0.25rem 0.5rem;
  margin: 0;
  cursor: ${(props) => (props.clickable ? 'n-resize' : 'text')};
`

const IssueAction = styled.p`
  background-color: #f3f3f3;
  padding: 0.1rem 0.3rem;
  margin: 0.2rem 0.2rem 0.2rem 0;
  border-radius: 2px;
  align-self: stretch;
  color: #555;
  cursor: default;

  &:hover {
    background-color: #eaeaea;
    color: #123;
  }
`

function entryShortList(
  entries: Entry[],
  clickToAddToSlot: boolean,
  dispatch: Dispatch<Msg>
) {
  return (
    <Pad>
      {entries.length ? (
        <IssueTagList>
          {entries.map((i) => (
            <IssueTag key={i.title}>
              <IssueTagText
                clickable={clickToAddToSlot}
                onClick={
                  clickToAddToSlot
                    ? () =>
                        dispatch({ type: 'add_idea_to_slot', idea: i.title })
                    : undefined
                }
              >
                {i.title}
              </IssueTagText>
              <IssueAction
                onClick={() =>
                  dispatch({
                    type: 'remove_brainstorm_idea',
                    idea: i.title,
                  })
                }
              >
                ×
              </IssueAction>
            </IssueTag>
          ))}
        </IssueTagList>
      ) : clickToAddToSlot ? (
        <Paragraph>
          Issues listed in the previous step will be listed here.
        </Paragraph>
      ) : (
        <>
          <Pad>
            <Paragraph>
              Write down some issues and they will be listed out here!
            </Paragraph>
          </Pad>
          <Pad>
            <Paragraph>
              These can be issues you care about, anyone else's issues, even
              issues you don't care about.
            </Paragraph>
          </Pad>
        </>
      )}
    </Pad>
  )
}

export function compact<A>(array: ReadonlyArray<A | undefined>): A[] {
  const list: A[] = []
  for (const item of array) {
    if (item !== undefined) {
      list.push(item)
    }
  }

  return list
}

function pageView(model: Model, dispatch: Dispatch<Msg>) {
  switch (model.page) {
    case 'intro': {
      return (
        <PageContainer>
          <div style={{ minHeight: '50vh' }}>
            <Split verticalCenter>
              <SplitPriority>
                <Pad>
                  <BigHeading>IssueFirst</BigHeading>
                  <Paragraph>Top issues exercise</Paragraph>
                </Pad>
              </SplitPriority>
              <SplitBlock />
              <img
                {...{
                  src: logo,
                  alt: '',
                  style: { opacity: 0.5, width: '3rem', height: '3rem' },
                }}
              />
            </Split>

            <Pad>
              <Split horizontal="center"></Split>
            </Pad>

            <Pad>
              <Paragraph>
                This is a loosely guided exercise in self reflection on your own
                political beliefs. The structure is: list issues, rank issues,
                and then optionally share your issues with others. The intended
                result is a well reasoned document describing the issues most
                important to you and their relative priority.
              </Paragraph>
            </Pad>
            <Pad>
              <Paragraph>
                While the format is fairly simple, think critically about your
                responses and weigh them against each other rigorously to get
                the most out of this exercise.
              </Paragraph>
            </Pad>
          </div>

          <Pad>
            <LargeRule />
            <Pad>
              <Split>
                <SplitPriority />
                <Button
                  {...{
                    title: "Let's get started →",
                    isEnabled: true,
                    onClick() {
                      dispatch({ type: 'change_page', page: 'brainstorm' })
                    },
                  }}
                />
              </Split>
            </Pad>
          </Pad>
        </PageContainer>
      )
    }
    case 'brainstorm': {
      return (
        <PageContainer>
          <div style={{ minHeight: '70vh' }}>
            <Pad>
              <BigHeading>List issues</BigHeading>
              <Paragraph>
                Articulate the issues that you are aware of in the world.
              </Paragraph>
            </Pad>

            <div style={{ maxWidth: '560px' }}>
              <SlotCard>
                <FormPadding>
                  {brainstormAddForm(model, 'Add issue', true, dispatch)}
                </FormPadding>
              </SlotCard>
            </div>
            {entryShortList(model.otherEntries, false, dispatch)}
          </div>

          <LargeRule />
          <Pad>
            <Split verticalCenter>
              <Button
                {...{
                  isEnabled: true,
                  title: 'Back',
                  size: 'compact',
                  onClick() {
                    dispatch({ type: 'change_page', page: 'intro' })
                  },
                }}
              />
              <SplitPriority />
              <Button
                {...{
                  isEnabled: true,
                  title: 'Move to next step',
                  kind: model.otherEntries.length > 5 ? 'success' : 'normal',
                  onClick() {
                    dispatch({ type: 'change_page', page: 'rank' })
                  },
                }}
              />
            </Split>
          </Pad>
        </PageContainer>
      )
    }
    case 'rank': {
      return (
        <PageContainer>
          <Pad>
            <BigHeading>Rank issues</BigHeading>

            <Paragraph>
              Rank and describe the three issues most important to you.
            </Paragraph>
          </Pad>

          {model.slots.map((slot, index) => (
            <Pad>
              <Slot
                onClick={
                  slot.type === 'empty'
                    ? () => dispatch({ type: 'fill_slot', slotIndex: index })
                    : undefined
                }
              >
                {slot.type === 'empty' ? (
                  <SlotEmpty>
                    <RankBadge>{index + 1}</RankBadge>
                    <Pad>
                      <Paragraph>
                        {' '}
                        {index === 0
                          ? 'The most important issue to you.'
                          : index === 1
                          ? 'The second most important issue to you.'
                          : 'The third most important issue to you.'}
                      </Paragraph>
                    </Pad>
                  </SlotEmpty>
                ) : (
                  <SlotCard>
                    <SlotPadding>
                      <Pad>
                        <Split horizontal="center">
                          <RankBadge>{index + 1}</RankBadge>
                        </Split>
                      </Pad>

                      <TextField
                        {...{
                          title:
                            index === 0
                              ? 'Your most important issue'
                              : index === 1
                              ? 'Your second most important issue'
                              : 'Your third most important issue',
                          value: slot.entry.title,
                          isEnabled: true,
                          onValue: (title) => {
                            dispatch({
                              type: 'update_entry',
                              slotIndex: index,
                              entryChanges: { title },
                            })
                          },
                        }}
                      />

                      <TextField
                        {...{
                          title: 'Why is this issue important?',
                          value: slot.entry.importance,
                          isMultipleLines: true,
                          isEnabled: true,
                          onValue: (importance) => {
                            dispatch({
                              type: 'update_entry',
                              slotIndex: index,
                              entryChanges: { importance },
                            })
                          },
                        }}
                      />

                      <TextField
                        {...{
                          title:
                            'What should happen (or not happen) regarding this issue?',
                          value: slot.entry.position,
                          isMultipleLines: true,
                          isEnabled: true,
                          onValue: (position) => {
                            dispatch({
                              type: 'update_entry',
                              slotIndex: index,
                              entryChanges: { position },
                            })
                          },
                        }}
                      />

                      <TextField
                        {...{
                          title: `Why is ${
                            slot.entry.title || 'this issue'
                          } more important than ${
                            findNextIssueTitle(model, index) || 'other issues'
                          }?`,
                          value: slot.entry.relativeImportance,
                          isMultipleLines: true,
                          isEnabled: true,
                          onValue: (relativeImportance) => {
                            dispatch({
                              type: 'update_entry',
                              slotIndex: index,
                              entryChanges: { relativeImportance },
                            })
                          },
                        }}
                      />
                    </SlotPadding>

                    <CardFooter>
                      <Split>
                        <ButtonSet>
                          <Button
                            {...{
                              title: 'Move up',
                              isEnabled: index !== 0,
                              size: 'compact',
                              onClick() {
                                dispatch({
                                  type: 'move_issue',
                                  direction: 'up',
                                  slotIndex: index,
                                })
                              },
                            }}
                          />
                          <Button
                            {...{
                              title: 'Move down',
                              isEnabled: true,
                              size: 'compact',
                              onClick() {
                                dispatch({
                                  type: 'move_issue',
                                  direction: 'down',
                                  slotIndex: index,
                                })
                              },
                            }}
                          />
                        </ButtonSet>
                        <SplitPriority />
                        <ButtonSet>
                          <Button
                            {...{
                              title: 'Remove',
                              isEnabled: true,
                              size: 'compact',
                              onClick() {
                                dispatch({
                                  type: 'remove_issue',
                                  slotIndex: index,
                                })
                              },
                            }}
                          />
                        </ButtonSet>
                      </Split>
                    </CardFooter>
                  </SlotCard>
                )}
              </Slot>
            </Pad>
          ))}
          <Pad>
            <Heading>Other issues</Heading>
            {entryShortList(model.otherEntries, true, dispatch)}
          </Pad>
          <LargeRule />
          <Pad>
            <Split verticalCenter>
              <Button
                {...{
                  isEnabled: true,
                  title: 'Back',
                  size: 'compact',
                  onClick() {
                    dispatch({ type: 'change_page', page: 'brainstorm' })
                  },
                }}
              />
              <SplitPriority />
              <Button
                {...{
                  isEnabled: true,
                  title: 'Move to next step',
                  onClick() {
                    dispatch({ type: 'change_page', page: 'review' })
                  },
                }}
              />
            </Split>
          </Pad>
        </PageContainer>
      )
    }
    case 'review': {
      return sharePage(model, dispatch)
    }
    default:
      absurd(model.page)
  }
}

function makeJsonDownloadURL(model: Model): string | undefined {
  const issueData = compact(
    model.slots.map((s) => (s.type === 'filled' ? s.entry : undefined))
  )

  if (!issueData.length) {
    return
  }
  const content = JSON.stringify(
    { version: 'experimental_not_stable', issues: model.slots },
    null,
    2
  )
  const blob = new Blob([content], { type: 'application/json' })
  const url = URL.createObjectURL(blob)

  return url
}

const DocumentContainer = styled.div`
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  padding-bottom: 3rem;
`

const DocumentWrapper = styled.div`
  padding: 1rem 0;
`

const IssueList = styled.ol`
  list-style: none;
  padding: 1rem 0;
  margin: 0;
`

const IssueListItem = styled.li`
  margin: 1rem 0;
`

const Summary = styled.summary`
  font-weight: bold;
  margin: -0.5em -0.5em 0;
  padding: 0.5em;
`

const Details = styled.details`
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  padding: 0.5em 0.5em 0;
  margin-bottom: 1rem;

  &[open] {
    padding: 0.5em;
  }

  &[open] ${Summary} {
    border-bottom: 1px solid #ccc;
    margin-bottom: 0.5em;
  }
`

function sharePage(model: Model, dispatch: Dispatch<Msg>) {
  return (
    <>
      <PageContainer>
        <Pad>
          <BigHeading>Review your issues</BigHeading>

          <Paragraph>
            Review your top three issues and consider sharing them with others!
          </Paragraph>
        </Pad>
        <LargeRule />
        <Pad>
          <Split verticalCenter>
            <Button
              {...{
                isEnabled: true,
                size: 'compact',
                title: 'Back',
                onClick() {
                  dispatch({ type: 'change_page', page: 'rank' })
                },
              }}
            />
            <SplitPriority />
            <ButtonLink
              {...{
                href: makeJsonDownloadURL(model),
                title: 'Download your issues (.json)',
                kind: 'primary',
                downloadFileName: 'issues.json',
                isEnabled: true,
              }}
            />
          </Split>
        </Pad>
      </PageContainer>
      <DocumentContainer>
        <DocumentWrapper>
          <PageContainer>
            <Pad>
              <BigHeading>Your issues</BigHeading>
              {/* <Paragraph>
                Here I present a prioritized list of the three issues which are
                most important to me. I accept that sharing my political views
                may reveal my ignorance on the world around me, and that's one
                reason why I'm sharing them: so I can re-evaluate when I'm
                wrong. I invite civil discussion, literature, and research.
              </Paragraph> */}
            </Pad>

            <IssueList>
              {model.slots.map((slot, index) => {
                const nextIssueTitle =
                  findNextIssueTitle(model, index) ?? 'other issues'

                let itemContent
                switch (slot.type) {
                  case 'empty':
                    itemContent = (
                      <>
                        <Pad>
                          <Heading>Work in progress</Heading>
                          <Pad>
                            <Paragraph>
                              I have not decided on a #{index + 1} issue yet.
                            </Paragraph>
                          </Pad>
                        </Pad>

                        <Details>
                          <Summary>
                            Why is this issue more important than{' '}
                            {nextIssueTitle}?
                          </Summary>
                          <Paragraph>Not yet answerable.</Paragraph>
                        </Details>
                      </>
                    )
                    break
                  case 'filled': {
                    itemContent = (
                      <>
                        <Pad>
                          <Heading>
                            {present(slot.entry.title) || '[name of issue]'}
                          </Heading>

                          <Pad>
                            <Paragraph>
                              {present(slot.entry.importance) ||
                                '[description of why the issue is important]'}
                            </Paragraph>
                          </Pad>

                          <Pad>
                            {present(slot.entry.position) ||
                              '[description of what should be done on this issue]'}
                          </Pad>
                        </Pad>
                        <Details>
                          <Summary>
                            Why is this issue more important than{' '}
                            {nextIssueTitle}?
                          </Summary>
                          <Paragraph>
                            {present(slot.entry.relativeImportance) ||
                              `[description of why this issue is more important than ${
                                index === 2 ? 'any other issues' : 'the next'
                              }]`}
                          </Paragraph>
                        </Details>
                      </>
                    )
                    break
                  }
                  default:
                    return absurd(slot)
                }

                return (
                  <IssueListItem key={index}>
                    <Split>
                      <Pad>
                        <RankBadge>{index + 1}</RankBadge>
                      </Pad>
                      <SplitBlock />
                      <SplitPriority>{itemContent}</SplitPriority>
                    </Split>
                  </IssueListItem>
                )
              })}
            </IssueList>

            {/* <Heading>Thank you for reading</Heading>
            <Paragraph>
              I appreciate you taking the time to read about the most important
              issues to me. If you enjoyed this format, consider creating your
              own list of issues! I created this document with{' '}
              <Link href="https://issuefirst.org">IssueFirst.org</Link>.
            </Paragraph> */}
          </PageContainer>
        </DocumentWrapper>
      </DocumentContainer>
    </>
  )
}

function present(text: string): string | undefined {
  const str = text.trim()
  return str.length ? str : undefined
}

function findNextIssueTitle(model: Model, slotIndex: number) {
  const slot = model.slots[slotIndex + 1]
  if (!(slot && slot.type === 'filled')) {
    return
  }

  return slot.entry.title
}
